import React from 'react';
import { navigate } from 'gatsby';
import uspsHook from '../hooks/uspsHook';
import testimonialsHook from '../hooks/testimonialsHook';
import Layout from '../components/layout';
import Section from '../components/Section/Section';
import SectionContent from '../components/SectionContent/SectionContent';
import SectionLabel from '../components/SectionLabel/SectionLabel';
import FeaturedPros from '../components/FeaturedPros/FeaturedPros';
import Button from '../components/Button/Button';
import TestimonialList from '../components/TestimonialList/TestimonialList';
import SEO from '../components/seo';


const Testimonials = () => {
  const usps = uspsHook();
  const testimonials = testimonialsHook();

  return (
    <Layout>
      <SEO title="Testimonials" />
      <Section>
        <SectionContent centered>
          <h2>Testimonials</h2>
          <TestimonialList testimonials={testimonials} />
        </SectionContent>
        <SectionContent centered>
          <SectionLabel label="Get a quote" />
          <h2>
            Talk to us about
            <br />
            your next project
          </h2>
          <Button onClick={() => navigate('/#contact')} label="Get in touch" cta />
        </SectionContent>
      </Section>

      <Section theme="dark">
        <FeaturedPros
          pros={usps}
        />
      </Section>
    </Layout>
  );
};

export default Testimonials;
