import { useStaticQuery, graphql } from 'gatsby';

const testimonialsHook = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTestimonial (
        filter: {
          quoteLong: {
            quoteLong: {
              ne: null
            }
          }
        }
      ) {
        edges {
          node {
            id
            name
            quoteLong {
              quoteLong
            }
          }
        }
      }
    }
  `);

  const testimonials = data.allContentfulTestimonial.edges.map(edge => ({
    key: edge.node.id,
    name: edge.node.name,
    quote: edge.node.quoteLong.quoteLong,
  }));

  return testimonials;
};

export default testimonialsHook;
