import React from 'react';
import PropTypes from 'prop-types';
import testimonialType from '../../proptypes/testimonialType';
import Testimonial from '../Testimonial/Testimonial';
import styles from './TestimonialList.module.scss';


const TestimonialList = ({ testimonials }) => (
  <div className={styles.wrapper}>
    {testimonials.map(testimonial => (
      <Testimonial
        key={testimonial.key}
        className={styles.testimonial}
        testimonial={testimonial}
      />
    ))}
  </div>
);

TestimonialList.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape(testimonialType),
  ).isRequired,
};

export default TestimonialList;
